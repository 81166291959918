@import "../../config/";
@import "../../mixins/typography";
@import "../../mixins/box-shadow";

.collection {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: map-get($map: $colors, $key: foreground);
  border-radius: map-get($map: $spacing, $key: borderRadius);
  overflow: hidden;
  transition: all 0.25s;

  &:hover {
    @include box-shadow();
  }

  &-content {
    background-color: map-get($map: $colors, $key: foreground);
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 40%;
    flex-basis: 40%;
    padding: map-get($map: $spacing, $key: sitePadding) * 2;
    align-self: center;

    button {
      margin-top: 1rem;
      max-width: 15rem;
    }
  }

  &-image-container {
    position: relative;
    width: 100%;
    flex-basis: 60%;
    min-height: 100%;
    align-self: stretch;

    >span {
      position: unset !important;
    }

    &-image {
      object-fit: cover;
      width: 100% !important;
      position: relative !important;
    }
  }

  h1 {
    @include heading();
  }

  h2 {
    margin-top: 0.75rem;
    @include copy();
  }

  @include breakpoint(large, max) {
    flex-direction: column;

    &-content {
      width: 99%;
      flex-basis: 99%;
      order: 1;
      padding: map-get($map: $spacing, $key: sitePadding);
    }

    &-image-container {
      min-width: 99%;
      flex-basis: 99%;
      order: 0;
    }
  }

  @include breakpoint(medium, max) {
    button {
      max-width: none;
    }
  }
}