@import "../../config";
@import "../../mixins/typography";

.text-content {
  display: flex;
  flex-direction: row;

  @include breakpoint(medium, max) {
    flex-direction: column;
  }
}

.row-reverse {
  flex-direction: row-reverse;

  @include breakpoint(medium, max) {
    flex-direction: column;
  }
}


.text {
  margin: 0.5rem;
  padding: 0.5rem;
  white-space: pre-wrap; // preserve formatting

  &.title {
    min-height: 3rem;
    @include type($font: heading, $size: large, $color: copyDark);
  }

  &.subtitle {
    min-height: 2.5rem;
    @include type($font: copyBold, $size: base, $color: copyDark);
  }

  &.paragraph {
    min-height: 2rem;
    @include type($font: copy, $size: base, $color: copyDark);
  }

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
}

.edit {
  background-color: blue;
}

.photo {
  position: relative;
  margin-right: 2rem;
  margin-bottom: 2rem;
  height: fit-content;
  width: fit-content;

  &-right {
    padding-left: 2rem;
  }

  --tiny: 100px;
  --small: 200px;
  --base: 300px;
  --large: 400px;
  --xl: 500px;

  &-tiny {
    width: var(--tiny);
    height: var(--tiny);
    min-width: var(--tiny);
    min-height: var(--tiny);
    max-height: var(--tiny);
    margin-right: 1.5rem;

    img {
      width: var(--tiny);
      height: var(--tiny);
      min-width: var(--tiny);
      min-height: var(--tiny);
      max-height: var(--tiny);
    }

    &-4-3 {
      height: calc(var(--tiny) * 0.75);
      min-height: calc(var(--tiny) * 0.75);
      max-height: calc(var(--tiny) * 0.75);

      img {
        height: calc(var(--tiny) * 0.75);
        min-height: calc(var(--tiny) * 0.75);
        max-height: calc(var(--tiny) * 0.75);
      }
    }

    &-3-4 {
      width: calc(var(--tiny) * 0.75);
      min-width: calc(var(--tiny) * 0.75);
      max-width: calc(var(--tiny) * 0.75);

      img {
        width: calc(var(--tiny) * 0.75);
        min-width: calc(var(--tiny) * 0.75);
        max-width: calc(var(--tiny) * 0.75);
      }
    }
  }

  &-small {
    width: var(--small);
    height: var(--small);
    min-width: var(--small);
    min-height: var(--small);
    max-height: var(--small);

    img {
      width: var(--small);
      height: var(--small);
      min-width: var(--small);
      min-height: var(--small);
      max-height: var(--small);
    }

    &-4-3 {
      height: calc(var(--small) * 0.75);
      min-height: calc(var(--small) * 0.75);
      max-height: calc(var(--small) * 0.75);

      img {
        height: calc(var(--small) * 0.75);
        min-height: calc(var(--small) * 0.75);
        max-height: calc(var(--small) * 0.75);
      }
    }

    &-3-4 {
      width: calc(var(--small) * 0.75);
      min-width: calc(var(--small) * 0.75);
      max-width: calc(var(--small) * 0.75);

      img {
        width: calc(var(--small) * 0.75);
        min-width: calc(var(--small) * 0.75);
        max-width: calc(var(--small) * 0.75);
      }
    }
  }

  &-base {
    width: var(--base);
    height: var(--base);
    min-width: var(--base);
    min-height: var(--base);
    max-height: var(--base);
    margin-right: 2.5rem;

    img {
      width: var(--base);
      height: var(--base);
      min-width: var(--base);
      min-height: var(--base);
      max-height: var(--base);
    }

    &-4-3 {
      height: calc(var(--base) * 0.75);
      min-height: calc(var(--base) * 0.75);
      max-height: calc(var(--base) * 0.75);

      img {
        height: calc(var(--base) * 0.75);
        min-height: calc(var(--base) * 0.75);
        max-height: calc(var(--base) * 0.75);
      }
    }

    &-3-4 {
      width: calc(var(--base) * 0.75);
      min-width: calc(var(--base) * 0.75);
      max-width: calc(var(--base) * 0.75);

      img {
        width: calc(var(--base) * 0.75);
        min-width: calc(var(--base) * 0.75);
        max-width: calc(var(--base) * 0.75);
      }
    }
  }

  &-large {
    width: var(--large);
    height: var(--large);
    min-width: var(--large);
    min-height: var(--large);
    max-height: var(--large);
    padding-right: 3rem;

    img {
      width: var(--large);
      height: var(--large);
      min-width: var(--large);
      min-height: var(--large);
      max-height: var(--large);
    }

    &-4-3 {
      height: calc(var(--large) * 0.75);
      min-height: calc(var(--large) * 0.75);
      max-height: calc(var(--large) * 0.75);

      img {
        height: calc(var(--large) * 0.75);
        min-height: calc(var(--large) * 0.75);
        max-height: calc(var(--large) * 0.75);
      }
    }

    &-3-4 {
      width: calc(var(--large) * 0.75);
      min-width: calc(var(--large) * 0.75);
      max-width: calc(var(--large) * 0.75);

      img {
        width: calc(var(--large) * 0.75);
        min-width: calc(var(--large) * 0.75);
        max-width: calc(var(--large) * 0.75);
      }
    }
  }

  &-xl {
    width: var(--xl);
    height: var(--xl);
    min-width: var(--xl);
    min-height: var(--xl);
    max-height: var(--xl);
    padding-right: 4rem;

    img {
      width: var(--xl);
      height: var(--xl);
      min-width: var(--xl);
      min-height: var(--xl);
      max-height: var(--xl);
    }

    &-4-3 {
      height: calc(var(--xl) * 0.75);
      min-height: calc(var(--xl) * 0.75);
      max-height: calc(var(--xl) * 0.75);

      img {
        height: calc(var(--xl) * 0.75);
        min-height: calc(var(--xl) * 0.75);
        max-height: calc(var(--xl) * 0.75);
      }
    }

    &-3-4 {
      width: calc(var(--xl) * 0.75);
      min-width: calc(var(--xl) * 0.75);
      max-width: calc(var(--xl) * 0.75);

      img {
        width: calc(var(--xl) * 0.75);
        min-width: calc(var(--xl) * 0.75);
        max-width: calc(var(--xl) * 0.75);
      }
    }
  }

  @include breakpoint(medium, max) {
    align-self: center;
  }

  @include breakpoint(small, max) {
    --large: 300px;
    --xl: 300px;
  }

  img {
    border-radius: map-get($map: $spacing, $key: borderRadius);
    object-fit: cover;
  }

  &:after {
    content: "";
    border-radius: map-get($map: $spacing, $key: borderRadius);
    position: absolute;
    bottom: -6%;
    right: -6%;
    width: 100%;
    height: 100%;
    background-color: map-get($map: $colors, $key: yellow);
    z-index: -1;
  }

  &-no-background {
    &:after {
      display: none;
    }
  }

  &-primary-bg {
    &:after {
      background-color: map-get($map: $colors, $key: primary);
    }
  }

  &-yellow-bg {
    &:after {
      background-color: map-get($map: $colors, $key: yellow);
    }
  }

  &-orient-left {
    margin-right: 1rem;
    margin-left: 2rem;

    &:after {
      right: auto;
      left: -6%;
    }
  }

  &-orient-right {
    &:after {
      right: -6%;
    }
  }
}

.text-container {
  flex: 1;
}

.video {
  border-radius: map-get($map: $spacing, $key: borderRadius);

  &-tiny {
    width: var(--tiny);
    height: var(--tiny);
    min-width: var(--tiny);
    min-height: var(--tiny);
    max-height: var(--tiny);

    &-4-3 {
      height: calc(var(--tiny) * 0.75);
      min-height: calc(var(--tiny) * 0.75);
      max-height: calc(var(--tiny) * 0.75);
    }

    &-3-4 {
      width: calc(var(--tiny) * 0.75);
      min-width: calc(var(--tiny) * 0.75);
      max-width: calc(var(--tiny) * 0.75);
    }
  }

  &-small {
    width: var(--small);
    height: var(--small);
    min-width: var(--small);
    min-height: var(--small);
    max-height: var(--small);

    &-4-3 {
      height: calc(var(--small) * 0.75);
      min-height: calc(var(--small) * 0.75);
      max-height: calc(var(--small) * 0.75);
    }

    &-3-4 {
      width: calc(var(--small) * 0.75);
      min-width: calc(var(--small) * 0.75);
      max-width: calc(var(--small) * 0.75);
    }
  }

  &-base {
    width: var(--base);
    height: var(--base);
    min-width: var(--base);
    min-height: var(--base);
    max-height: var(--base);

    &-4-3 {
      height: calc(var(--base) * 0.75);
      min-height: calc(var(--base) * 0.75);
      max-height: calc(var(--base) * 0.75);
    }

    &-3-4 {
      width: calc(var(--base) * 0.75);
      min-width: calc(var(--base) * 0.75);
      max-width: calc(var(--base) * 0.75);
    }
  }

  &-large {
    width: var(--large);
    height: var(--large);
    min-width: var(--large);
    min-height: var(--large);
    max-height: var(--large);

    &-4-3 {
      height: calc(var(--large) * 0.75);
      min-height: calc(var(--large) * 0.75);
      max-height: calc(var(--large) * 0.75);
    }

    &-3-4 {
      width: calc(var(--large) * 0.75);
      min-width: calc(var(--large) * 0.75);
      max-width: calc(var(--large) * 0.75);
    }
  }

  &-xl {
    width: var(--xl);
    height: var(--xl);
    min-width: var(--xl);
    min-height: var(--xl);
    max-height: var(--xl);

    &-4-3 {
      height: calc(var(--xl) * 0.75);
      min-height: calc(var(--xl) * 0.75);
      max-height: calc(var(--xl) * 0.75);
    }

    &-3-4 {
      width: calc(var(--xl) * 0.75);
      min-width: calc(var(--xl) * 0.75);
      max-width: calc(var(--xl) * 0.75);
    }
  }
}