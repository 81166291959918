@import '../../config/';
@import '../../mixins/typography';
@import '../../mixins/box-shadow';
@import '../../mixins/link';
@import '../../mixins/truncate';
@import '../../mixins/loading';

.bookstores {
  &-tile {
    min-width: 25rem;
    width: 25rem;
    border-radius: map-get($map: $spacing, $key: borderRadius);
    padding: map-get($map: $spacing, $key: sitePadding);
    background-color: map-get($map: $colors, $key: foreground);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid map-get($map: $colors, $key: borderLight);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 20px;

    &:hover {
      box-shadow: 0px 3px 0.75em 0 rgba(map-get($map: $colors, $key: copy), 0.35);
    }
  }

  &-seller {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 5rem;

    &-info {
      h2 {
        max-width: 10rem;
        margin-bottom: 0.25rem;
        @include heading($font-size: base);
        @include link();
        @include truncate(1);
      }
    }

    &-reviews {
      cursor: pointer;
    }

    button {
      margin-left: auto;
      width: 25%;
    }

    &-profile-image {
      @include skeleton();
      border-radius: 50%;
      position: relative;
      min-width: 5rem;
      min-height: 5rem;
      width: 5rem;
      height: 5rem;
      margin-right: 1.5rem;

      span {
        @include type($size: tiny);
        padding: 0;
      }

      img {
        border-radius: 50%;
      }
    }
  }

  &-image-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'main main secondary'
      'main main tertiary';

    height: 15rem;
    gap: map-get($map: $spacing, $key: sitePadding) * 0.25;
    border-radius: map-get($map: $spacing, $key: borderRadius);
    margin-bottom: 1rem;
    overflow: hidden;
  }

  &-book-image {
    @include skeleton();
    border-radius: 0;
    width: 100%;
    cursor: pointer;
    position: relative;
    min-height: 100%;
    transition: all 0.25s;
    overflow: hidden;
    grid-area: main;
    justify-self: stretch;

    &--secondary {
      grid-area: secondary;
    }

    &--tertiary {
      grid-area: tertiary;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  @include breakpoint(medium, max) {
    button {
      max-width: none;
    }
  }
}

.sale-callout-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 2rem;
    margin-right: 0.5rem;
  }
}

.sales-callouts-container {
  margin-bottom: 1rem;
}
