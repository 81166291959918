@import '../config';
@import '../mixins/typography';
@import '../mixins/box-shadow';

.dropdown-container {
  display: flex;
  flex-direction: column;
  padding: map-get($spacing, 'sitePadding');
  @include box-shadow;

  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-width: 10rem;
    border-radius: map-get($map: $spacing, $key: borderRadius);
    padding: 0.75rem;
    gap: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(map-get($map: $colors, $key: primary), 0.08);
    }
  }

  &-label {
    @include type($font: copy, $size: small, $color: copyDark);
    line-height: 1.5;
    text-wrap: nowrap;
  }

  &-icon {
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
  }
}

.remove-book-modal {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.tile-more-icon {
  position: relative;
  top: 0;
  left: -0.5rem;
  padding: 0.3rem;
  border-radius: 50%;
  opacity: 0.7;
  background-color: map-get($map: $colors, $key: borderLight);
  transition: opacity 0.3s ease;
  @include box-shadow;

  &:hover {
    opacity: 1;
  }
}
