@import "../../config/";
@import "../../mixins/typography";

.tags {
  &-title {
    @include heading();
    margin-bottom: 1rem;
  }

  &-links {
    > * {
      float: left;
      margin-right: 0.75rem;
      margin-bottom: 0.75rem;
    }

    :last-child {
      margin-bottom: 0;
    }

    &::after {
      content: "";
      clear: both;
      display: table;
    }
  }
}
