@import "../../config/";

.container {
  margin-top: 2.5rem;

  @include breakpoint(medium, max) {
    margin-top: 2rem;
  }

  .component {
    margin-bottom: 2.5rem;

    @include breakpoint(medium, max) {
      margin-bottom: 1.5rem;
    }

    &.callout {
      margin-bottom: 4rem;

      @include breakpoint(medium, max) {
        margin-bottom: 3rem;
      }
    }

    &.text {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: auto;
      gap: map-get($map: $spacing, $key: sitePadding);

      @include breakpoint(x-large, max) {
        grid-template-columns: 1fr 1fr;
      }

      @include breakpoint(medium, max) {
        grid-template-columns: 1fr;
      }
    }
  }
}

.col-1 {
  grid-column: span 1;
}

.col-2 {
  grid-column: span 2;

  @include breakpoint(medium, max) {
    grid-column: span 1;
  }
}

.col-3 {
  grid-column: span 3;

  @include breakpoint(large, max) {
    grid-column: span 2;
  }

  @include breakpoint(medium, max) {
    grid-column: span 1;
  }
}

.col-4 {
  grid-column: span 4;

  @include breakpoint(large, max) {
    grid-column: span 2;
  }

  @include breakpoint(medium, max) {
    grid-column: span 1;
  }
}