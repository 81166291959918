@import "../../config/";
@import "../../mixins/typography";

.container {
  position: relative;
  border-radius: map-get($map: $spacing, $key: borderRadius);
  margin: 0 auto;

  @include breakpoint(small, max) {
    width: 100% !important;
  }

  img {
    border-radius: map-get($map: $spacing, $key: borderRadius);
    width: 100%;
    height: auto;
  }

  .caption {
    text-align: center;
    margin-top: 0.5rem;
    @include type($font: copy, $size: small, $color: copyDark);
  }
}